import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  search: Yup.string(),
  showMyProject: Yup.string(),
  brands: Yup.array(),
  regions: Yup.array(),
  countries: Yup.array(),
  categories: Yup.array(),
});

export const initialValues: any = {
  text: "",
  showMyProject: true,
  brands: [],
  regions: [],
  countries: [],
  categories: [],
};

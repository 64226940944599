import { computed } from "mobx";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useField } from "formik";
import { FC, useCallback, useEffect, useState } from "react";

import {
  SharedComponents,
  Types as SharedTypes,
  SharedStore,
  Theme,
} from "@shared";
import { CommonPages } from "@modules";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

import * as LocalComponents from "./components";

interface IVideoProjectsPage {}

export const VideoProjectsPage: FC<IVideoProjectsPage> = observer(() => {
  /**
   * theme data
   * */
  const {
    spacing: { md },
  } = Theme.useStyledTheme();

  const [searchModalOpened, setSearchModalOpened] = useState(false);
  /**
   * formik data
   * */
  const [input, , helpers] = useField<boolean>("showAllProject");
  /**
   * navigation helpers
   * */
  const navigate = useNavigate();
  /**
   * storage values
   * */
  const {
    data: { hasNonEmpRole, hasEBPRole, hasNoneRole },
    // data: { hasAdminRole, hasNonEmpRole, hasEBPRole, hasNoneRole },
  } = SharedStore;
  const {
    data: {
      projects,
      myProjectsStats,
      projectsStats,
      hasMoreProjects,
      setCurrentProjectsPage,
      setMoreProjects,
      loadings,
    },
    getMoreProjects,
    getMoreUserProjects,
    getProjects,
    getProjectsStats,
    getMyProjects,
    getMyProjectsStats,
  } = VideoAnalyticsStore;

  const projectsQueryHasLoading = computed(
    () =>
      loadings.get(Types.Endpoints.PROJECTS) ||
      loadings.get(Types.Endpoints.USER_PROJECTS)
  ).get();
  const projectsStatsQueryHasLoading = computed(() =>
    loadings.get(Types.Endpoints.PROJECTS_STATS)
  ).get();
  const deleteProjectMutationHasLoading = computed(() =>
    loadings.get(Types.Endpoints.DELETE_PROJECT)
  ).get();

  const listData = computed(() => projects).get();
  const statsData = computed(() =>
    input.value ? myProjectsStats : projectsStats
  ).get();

  const next = input.value ? getMoreUserProjects : getMoreProjects;

  const onClick = useCallback(() => {
    // ADO
    if (hasEBPRole) return;
    helpers.setValue(!input.value);
  }, [helpers, input, hasEBPRole]);

  /**
   * side effects
   * */
  useEffect(() => {
    if (input.value) {
      getMyProjects();
      getMyProjectsStats();
    } else {
      getProjects();
      getProjectsStats();
    }

    return () => {
      setCurrentProjectsPage(1);
      setMoreProjects(true);
    };
  }, [input.value]);

  /**
   * ui if statements
   * */
  if (
    projectsQueryHasLoading ||
    projectsStatsQueryHasLoading ||
    deleteProjectMutationHasLoading
  ) {
    return <SharedComponents.LoadingIndicator />;
  }

  if (hasNoneRole) {
    return <SharedComponents.DeniedIndicator />;
  }

  return (
    <SharedComponents.Box width={"100%"}>
      <LocalComponents.SearchModal
        isOpened={searchModalOpened}
        onClose={() => setSearchModalOpened(false)}
      />
      <SharedComponents.VerticalBox height={32} />
      <SharedComponents.Row
        flex={0}
        height={"fit-content"}
        justifyContent={"flex-end"}
      >
        {!hasEBPRole && !hasNoneRole && (
          <SharedComponents.Row>
            <SharedComponents.FormikLib.Radio
              subtitle={"My projects"}
              field={"showMyProject"}
              onClick={onClick}
              value={input.value}
            />
            <SharedComponents.HorizontalBox width={md} />
            <SharedComponents.FormikLib.Radio
              subtitle={"Shared projects"}
              field={"showSharedProject"}
              onClick={onClick}
              value={!input.value}
            />
          </SharedComponents.Row>
        )}
        {!hasEBPRole && !hasNonEmpRole && !hasNoneRole && (
          <SharedComponents.Button
            type={SharedTypes.ButtonType.FILLED}
            text={"ADD PROJECT"}
            onClick={() =>
              navigate(SharedTypes.PATHS.VIDEO_ANALYTICS_PROJECT_ADD)
            }
          />
        )}
      </SharedComponents.Row>
      <SharedComponents.VerticalBox height={16} />
      <LocalComponents.SearchBar onClick={() => setSearchModalOpened(true)} />
      {/*<SharedComponents.AdvancedFilters*/}
      {/*  searchField="text"*/}
      {/*  data={advancedFiltersData}*/}
      {/*  isElastic*/}
      {/*/>*/}
      <SharedComponents.VerticalBox height={16} />
      {listData.length === 0 && <CommonPages.Empty />}
      {listData.length > 0 && (
        <>
          <LocalComponents.Summary data={statsData} />
          <SharedComponents.InfiniteScrollList
            data={projects}
            hasMore={hasMoreProjects}
            next={next}
            ListItem={LocalComponents.ListItem}
          />
        </>
      )}
    </SharedComponents.Box>
  );
});
